var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dropdown" },
    [
      _c(
        "a-card",
        {
          staticClass: "gx-card-table-full text-center",
          attrs: { title: "Select Team For Manage" },
        },
        [
          _c(
            "a-select",
            {
              staticStyle: {
                width: "150px",
                "margin-bottom": "10px",
                "margin-right": "10px",
              },
              on: { change: _vm.onChangeTeam },
              model: {
                value: _vm.selectedTeamId,
                callback: function ($$v) {
                  _vm.selectedTeamId = $$v
                },
                expression: "selectedTeamId",
              },
            },
            [
              _c(
                "a-select-option",
                { attrs: { disabled: "", hidden: "", value: "" } },
                [_vm._v("Select One")]
              ),
              _vm._v(">\n      "),
              _vm._l(_vm.teams, function (team, index) {
                return _c(
                  "a-select-option",
                  { key: index, attrs: { value: team.id } },
                  [_vm._v("\n        " + _vm._s(team.team_name) + "\n      ")]
                )
              }),
            ],
            2
          ),
          _c(
            "a-button",
            {
              attrs: { type: "primary", disabled: !_vm.selectedTeamId },
              on: { click: _vm.manageTeamRedirect },
            },
            [_vm._v("\n      Manage Team\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }