var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "n-page",
    [
      _vm.user.select_role === "member"
        ? _c(
            "div",
            [
              _c("div", { staticStyle: { display: "flex" } }, [
                _c("p", { staticStyle: { "margin-right": "20px" } }, [
                  _c("span", {
                    staticStyle: {
                      padding: "6px",
                      background: "#559f3f",
                      height: "10px",
                      display: "inline-block",
                      width: "10px",
                      "border-radius": "50%",
                    },
                  }),
                ]),
              ]),
              _c(
                "a-card",
                {
                  staticClass: "gx-card-full",
                  attrs: { title: "Your Schedules" },
                },
                [_c("member-calendar")],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "a-card",
                { staticClass: "gx-card-full", attrs: { title: "Events" } },
                [_c("ClubDashboard")],
                1
              ),
            ],
            1
          ),
      _vm.user.select_role === "coach"
        ? _c("div", [
            _c(
              "div",
              { staticClass: "coach-welcome-modal" },
              [
                _c(
                  "a-modal",
                  {
                    staticClass: "welcome-modal",
                    attrs: { "mask-closable": false },
                    model: {
                      value: _vm.visible,
                      callback: function ($$v) {
                        _vm.visible = $$v
                      },
                      expression: "visible",
                    },
                  },
                  [
                    _c(
                      "a-carousel",
                      {
                        staticClass: "coach-slides",
                        attrs: { "after-change": _vm.onChange, arrows: "" },
                        scopedSlots: _vm._u(
                          [
                            _vm.displayCoachPreviousBtn
                              ? {
                                  key: "prevArrow",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "custom-slick-arrow",
                                          staticStyle: {
                                            "z-index": "1",
                                            left: "8px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              Previous\n            "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                            _vm.displayCoachNextBtn
                              ? {
                                  key: "nextArrow",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "custom-slick-arrow",
                                          staticStyle: { right: "8px" },
                                        },
                                        [_vm._v("Next")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "a-row",
                              {
                                staticClass: "gx-pt-5",
                                attrs: { type: "flex", align: "middle" },
                              },
                              [
                                _c("a-col", { attrs: { lg: 14, md: 24 } }, [
                                  _c("h1", [_vm._v("WELCOME")]),
                                  _c("h2", [_vm._v("To Subsapp.com")]),
                                  _c("p", [
                                    _vm._v(
                                      "You have successfully logged in as a Coach."
                                    ),
                                  ]),
                                ]),
                                _c("a-col", { attrs: { lg: 10, md: 24 } }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/images/coach-slide1.png"),
                                      alt: "slide1",
                                    },
                                  }),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "a-row",
                              {
                                staticClass: "gx-pb-5",
                                attrs: { type: "flex", align: "middle" },
                              },
                              [
                                _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                                  _c("h3", [
                                    _vm._v(
                                      "\n                  Coach can check the Events and can manage his team Schedule.\n                "
                                    ),
                                  ]),
                                ]),
                                _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/images/coach-slide2.png"),
                                      alt: "slide2",
                                    },
                                  }),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "a-row",
                              {
                                staticClass: "gx-pb-5",
                                attrs: { type: "flex", align: "middle" },
                              },
                              [
                                _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                                  _c("h3", [_vm._v("Coach can")]),
                                  _c("p", { staticClass: "gx-mb-0" }, [
                                    _vm._v("Add and Search"),
                                  ]),
                                  _c("h3", [_vm._v("Members. ")]),
                                ]),
                                _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/images/coach-slide3.png"),
                                      alt: "slide3",
                                    },
                                  }),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "a-row",
                              {
                                staticClass: "gx-pt-5",
                                attrs: { type: "flex", align: "middle" },
                              },
                              [
                                _c("a-col", { attrs: { lg: 14, md: 24 } }, [
                                  _c("h2", [_vm._v("Coach can")]),
                                  _c("h4", { staticClass: "gx-mb-0" }, [
                                    _vm._v("manage and"),
                                  ]),
                                  _c("h3", [_vm._v("create new team.")]),
                                ]),
                                _c("a-col", { attrs: { lg: 10, md: 24 } }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/images/coach-slide4.png"),
                                      alt: "slide4",
                                    },
                                  }),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "a-row",
                              {
                                staticClass: "gx-pt-5",
                                attrs: { type: "flex", align: "middle" },
                              },
                              [
                                _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                                  _c("h2", [_vm._v("Coach")]),
                                  _c("p", { staticClass: "gx-mb-0" }, [
                                    _vm._v("can check"),
                                  ]),
                                  _c("h2", [_vm._v("the club list.")]),
                                ]),
                                _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/images/coach-slide5.png"),
                                      alt: "slide5",
                                    },
                                  }),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    !_vm.displayCoachNextBtn
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "custom-slick-arrow click-btn custom-btn",
                            on: { click: _vm.updateIsloggedIn },
                          },
                          [_vm._v("\n          Click here\n        ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.user.select_role === "club_admin"
        ? _c("div", [
            _c(
              "div",
              { staticClass: "coach-welcome-modal" },
              [
                _c(
                  "a-modal",
                  {
                    staticClass: "welcome-modal",
                    attrs: { "mask-closable": false },
                    model: {
                      value: _vm.clubModalVisible,
                      callback: function ($$v) {
                        _vm.clubModalVisible = $$v
                      },
                      expression: "clubModalVisible",
                    },
                  },
                  [
                    _c(
                      "a-carousel",
                      {
                        staticClass: "coach-slides",
                        attrs: { "after-change": _vm.onClubChange, arrows: "" },
                        scopedSlots: _vm._u(
                          [
                            _vm.displayAdminPreviousBtn
                              ? {
                                  key: "prevArrow",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "custom-slick-arrow desktop-slider-icon",
                                          staticStyle: {
                                            "z-index": "1",
                                            left: "8px",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "previous_text" },
                                            [_vm._v(" Previous ")]
                                          ),
                                          _c("a-icon", {
                                            staticClass: "previous_icon",
                                            attrs: { type: "left" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                            _vm.displayAdminNextBtn
                              ? {
                                  key: "nextArrow",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "custom-slick-arrow desktop-slider-icon",
                                          staticStyle: { right: "8px" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "next_text" },
                                            [_vm._v(" Next ")]
                                          ),
                                          _c("a-icon", {
                                            staticClass: "next_icon",
                                            attrs: { type: "right" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "a-row",
                              {
                                staticClass:
                                  "gx-pt-1 ant-row-flex ant-row-flex-middle",
                                attrs: { type: "flex", align: "middle" },
                              },
                              [
                                _c("a-col", { attrs: { lg: 14, md: 24 } }, [
                                  _c("h1", [_vm._v("WELCOME")]),
                                  _c("h2", [_vm._v("To Subsapp.com")]),
                                  _c("p", [
                                    _vm._v(
                                      "You have successfully logged in as a Club admin."
                                    ),
                                  ]),
                                ]),
                                _c("a-col", { attrs: { lg: 10, md: 24 } }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/images/coach-slide1.png"),
                                      alt: "slide1",
                                    },
                                  }),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "a-row",
                              {
                                staticClass: "gx-pt-1",
                                attrs: { type: "flex", align: "middle" },
                              },
                              [
                                _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                                  _c("h2", [_vm._v("Event")]),
                                  _c("p", { staticClass: "gx-mb-0" }, [
                                    _vm._v("Find what's on, where and when."),
                                  ]),
                                ]),
                                _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/images/Club-slider2-Eventst.png"),
                                      alt: "slide2",
                                    },
                                  }),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "a-row",
                              {
                                staticClass: "gx-pt-0",
                                attrs: { type: "flex", align: "middle" },
                              },
                              [
                                _c("a-col", { attrs: { lg: 14, md: 24 } }, [
                                  _c("h3", [_vm._v("Members")]),
                                  _c("p", { staticClass: "gx-mb-0" }, [
                                    _vm._v(
                                      "\n                  Details of club and team members at your fingertips, copy\n                  and paste the email address to quickly add to teams etc.\n                "
                                    ),
                                  ]),
                                ]),
                                _c("a-col", { attrs: { lg: 10, md: 24 } }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/images/coach-slide3.png"),
                                      alt: "slide3",
                                    },
                                  }),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "a-row",
                              {
                                staticClass: "gx-pt-0",
                                attrs: { type: "flex", align: "middle" },
                              },
                              [
                                _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                                  _c("h2", [_vm._v("Team")]),
                                  _c("p", { staticClass: "gx-mb-0" }, [
                                    _vm._v(
                                      "\n                  Find out your teams or request to be added.\n                "
                                    ),
                                  ]),
                                ]),
                                _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/images/coach-slider3teamimg.png"),
                                      alt: "slide4",
                                    },
                                  }),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "a-row",
                              {
                                staticClass: "gx-pt-0",
                                attrs: { type: "flex", align: "middle" },
                              },
                              [
                                _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                                  _c("h2", [_vm._v("Coaches")]),
                                  _c("p", { staticClass: "gx-mb-0" }, [
                                    _vm._v(
                                      "\n                  Team and club coach details are found here.\n                "
                                    ),
                                  ]),
                                ]),
                                _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/images/Club-slider5-coatches.png"),
                                      alt: "slide5",
                                    },
                                  }),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "a-row",
                              {
                                staticClass: "gx-pt-0",
                                attrs: { type: "flex", align: "middle" },
                              },
                              [
                                _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                                  _c("h3", [_vm._v("Subscription")]),
                                  _c("p", { staticClass: "gx-mb-0" }, [
                                    _vm._v(
                                      "\n                  Choose a plan that suits your club, 1 flat monthly fee with\n                  no hidden charges. No contract.\n                "
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "a-col",
                                  { attrs: { lg: 12, md: 24, center: "" } },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("../../assets/images/Club-slider6-subscriptions-amico.png"),
                                        alt: "slide6",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "a-row",
                              {
                                staticClass: "gx-pt-0",
                                attrs: { type: "flex", align: "middle" },
                              },
                              [
                                _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                                  _c("h2", [_vm._v("Club Details")]),
                                  _c("p", { staticClass: "gx-mb-0" }, [
                                    _vm._v(
                                      "Add your clubs logo/photo and details."
                                    ),
                                  ]),
                                ]),
                                _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/images/Club-slider7-clubDetails.png"),
                                      alt: "slide7",
                                    },
                                  }),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "a-row",
                              {
                                staticClass: "gx-pt-0",
                                attrs: { type: "flex", align: "middle" },
                              },
                              [
                                _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                                  _c("h2", [_vm._v("My Profile")]),
                                  _c("p", { staticClass: "gx-mb-0" }, [
                                    _vm._v(
                                      "\n                  Space for the big cheese of the club, contact details etc.\n                "
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "a-col",
                                  { attrs: { lg: 12, md: 24, sm: 15 } },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("../../assets/images/coatch-slider8-profile.png"),
                                        alt: "slide8",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    !_vm.displayAdminNextBtn
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "custom-slick-arrow click-btn custom-btn",
                            on: { click: _vm.updateIsloggedIn },
                          },
                          [
                            _c("span", { staticClass: "oK_button_text" }, [
                              _vm._v(" Ok "),
                            ]),
                            _c("a-icon", {
                              staticClass: "close_button",
                              attrs: { type: "close" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      [
        _c(
          "div",
          { attrs: { id: "app" } },
          [
            _c(
              "vue-cookie-toggler",
              {
                attrs: {
                  "cookies-groups": _vm.cookiesGroups,
                  title: "Cookie Settings",
                  "accept-label": "Accept button label",
                  "save-label": "Save button label",
                  "cancel-label": "Cancel button label",
                },
                scopedSlots: _vm._u([
                  {
                    key: "settingsContent",
                    fn: function () {
                      return [_vm._v(" Select the cookies ")]
                    },
                    proxy: true,
                  },
                  {
                    key: "mainButtons",
                    fn: function ({ accept, settings }) {
                      return [
                        _c(
                          "button",
                          {
                            staticClass: "vct__btn vct__btn--primary",
                            on: { click: accept },
                          },
                          [_vm._v("\n        Accept all cookies\n      ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "settingsButtons",
                    fn: function ({ accept, save }) {
                      return [
                        _c(
                          "button",
                          {
                            staticClass: "vct__btn vct__btn--default",
                            on: { click: accept },
                          },
                          [_vm._v("\n        Accept all cookies\n      ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "vct__btn vct__btn--primary",
                            on: { click: save },
                          },
                          [_vm._v("\n        Save settings\n      ")]
                        ),
                      ]
                    },
                  },
                ]),
              },
              [
                _vm._v(
                  "\n  When you visit any of our websites, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies.\n\n    "
                ),
              ]
            ),
            _c("div", { staticClass: "flex justify-center align-center" }, [
              _c("button", { attrs: { "data-cookie-toggler": "settings" } }, [
                _vm._v("Open cookies settings"),
              ]),
              _c("iframe", {
                attrs: {
                  "data-cookie": "",
                  "data-category": "analytics",
                  "data-placeholder": "true",
                  alt: "Please accept the cookie policy to see the content",
                  "data-src": "https://www.youtube.com/embed/azUbCdcAeFM",
                },
              }),
            ]),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }